<template>
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2024©</span>
        <a
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >Desenvolvido By Nuts Tecnologia</a
        >
      </div>
      <div class="nav nav-dark order-1 order-md-2">
        <a href="/#/termos" target="_blank" class="nav-link pr-3 pl-0"
          >Termos</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
