<template>
  <div style="position: relative">
    <div style="position: relative">
      <FilterFields
        :fields="[
          {
            type: 'text',
            label: 'Documento',
            field: 'documento',
          },
          {
            type: 'text',
            label: 'Cliente',
            field: 'cliente',
          },
          {
            type: 'text',
            label: 'CNPJ/CPF',
            field: 'cnpj_cpf',
          },
          {
            type: 'text',
            label: 'Descrição',
            field: 'descricao',
          },
          {
            type: 'text',
            label: 'Meio Pag.',
            field: 'meio_pagamento',
          },
          {
            type: 'select',
            label: 'Status',
            field: 'status',
            options: ['Vencida', 'A receber', 'Recebida', 'Todos'],
          },
          {
            type: 'date',
            label: 'Vencimento',
            field: 'data_vencimento',
          },
          {
            type: 'date',
            label: 'Recebimento',
            field: 'data_recebimento',
          },
          {
            type: 'date',
            label: 'Emissão',
            field: 'created_at',
          },
          {
            type: 'select',
            label: 'Marcada',
            field: 'conta_marcada',
            options: ['Sim', 'Não'],
          },
        ]"
      />
      <div
        class="d-flex align-items-center my-2"
        style="position: absolute; top: -7px; left: 110px; z-index: 99"
      >
        <b-dropdown
          :text="filtersStore.filterOptionSelected.label"
          split
          split-variant="secondary"
          id="menu"
          variant="primary"
          v-b-tooltip.right="''"
        >
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 1"
            @click="
              () =>
                (filtersStore.filterOptionSelected = { id: 1, label: 'Hoje' })
            "
            >Hoje</b-dropdown-item
          >
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 2"
            @click="
              () =>
                (filtersStore.filterOptionSelected = { id: 2, label: 'Ontem' })
            "
          >
            Ontem
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 3"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 3,
                  label: 'Essa semana',
                })
            "
          >
            Essa semana
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 4"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 4,
                  label: 'Semana passada',
                })
            "
          >
            Semana passada
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 5"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 5,
                  label: 'Esse mês',
                })
            "
          >
            Esse mês
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item v-b-modal.menuAcoes
        >Recebimento de contas</b-dropdown-item
      >
      <b-dropdown-item
        v-b-modal.receberParcial
        :disabled="actionsStore.selectedRows.length > 1"
        @click="
          () =>
            (dataVencimentoParcial =
              actionsStore.selectedRows[0].data_vencimento)
        "
        >Recebimento Parcial
      </b-dropdown-item>
      <b-dropdown-item
        @click="
          () =>
            getRequestAdmin(() => {
              $bvModal.show('estornarContas');
            })
        "
        >Estornar Contas</b-dropdown-item
      >
      <b-dropdown-item v-b-modal.gerarCarne
        >Gerar carnê de boletos</b-dropdown-item
      >
      <b-dropdown-item
        v-b-modal.gerarRecibo
        :disabled="actionsStore.selectedRows.length > 1"
        >Gerar recibo</b-dropdown-item
      >
      <b-dropdown-item v-b-modal.gerarCarneLoja
        >Gerar carnê loja</b-dropdown-item
      >
      <b-dropdown-item v-b-modal.gerarCarneBoleto
        >Gerar carnê de boletos reduzido</b-dropdown-item
      >
      <b-dropdown-item v-b-modal.marcarContas
        >Marcar/Desmarcar Contas</b-dropdown-item
      >
    </b-dropdown>

    <b-modal
      id="marcarContas"
      title="Deseja marcar as Contas?"
      centered
      size="xs"
    >
      <p>
        Recurso utilizado para melhorar a visualização das contas selecionadas.
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="marcar"
            ref="marcarContas"
            class="mr-5"
          >
            Marcar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="gerarRecibo" title="Recibo" centered size="xs">
      <div class="d-flex">
        <p>Será gerado um recibo referente a conta selecionada</p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarRecibo"
            ref="gerarRecibo"
            class="mr-5"
          >
            Gerar recibo
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="gerarCarneBoleto"
      title="Deseja gerar carnê todas as contas selecionadas?"
      centered
      size="xs"
    >
      <div class="d-flex">
        <p>
          Lembrando que será gerado o carnê somente de contas para o mesmo
          cliente.
        </p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarCarneBoletoReduzido"
            ref="gerarCarneBoleto"
            class="mr-5"
          >
            Gerar carnê
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="gerarCarne"
      title="Deseja agrupar todas as contas selecionadas?"
      centered
      size="xs"
    >
      <div class="d-flex">
        <p>
          Lembrando que será gerado o carnê somente de contas para o mesmo
          cliente.
        </p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarCarne"
            ref="gerarCarne"
            class="mr-5"
          >
            Gerar carnê
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="gerarCarneLoja"
      title="Deseja agrupar todas as contas selecionadas?"
      centered
      size="xs"
    >
      <div class="d-flex">
        <p>
          Lembrando que será gerado o carnê somente de contas para o mesmo
          cliente.
        </p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarCarneLoja"
            ref="gerarCarneLoja"
            class="mr-5"
          >
            Gerar carnê
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="estornarContas"
      title="Deseja estornar as Contas?"
      centered
      size="xs"
    >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="estornar"
            ref="estornar_contas"
            class="mr-5"
          >
            Estornar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="receberParcial"
      title="Recebimento parcial de Conta?"
      centered
      size="lg"
      v-if="actionsStore.selectedRows.length > 0"
    >
      <b-row class="mb-4">
        <b-col>
          <b-form-checkbox
            name="nfe-saida"
            switch
            size="lg"
            v-model="descontoParcial"
          >
            Receber Parcial / Desconto
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group id="documento" label-for="documento" label="Documento">
            <b-form-input
              :value="actionsStore.selectedRows[0]?.documento"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="cliente" label-for="cliente" label="Cliente">
            <b-form-input
              :value="actionsStore.selectedRows[0]?.cliente"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="data_vencimento"
            label-for="data_vencimento"
            label="Vencimento"
          >
            <b-form-input
              :value="formatDate(actionsStore.selectedRows[0]?.data_vencimento)"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="data_recebimento"
            label-for="data_recebimento"
            label="Recebimento"
          >
            <b-form-input
              :value="
                formatDate(actionsStore.selectedRows[0]?.data_recebimento)
              "
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="valor" label-for="valor" label="Valor R$">
            <b-form-input
              :value="
                numberToReal(
                  parseFloat(actionsStore.selectedRows[0]?.valor ?? 0),
                  2
                )
              "
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="juros" label-for="juros" label="Juros R$">
            <b-form-input
              :value="
                numberToReal(
                  parseFloat(actionsStore.selectedRows[0]?.juros ?? 0),
                  2
                )
              "
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="multa" label-for="multa" label="Multa R$">
            <b-form-input
              :value="
                numberToReal(
                  parseFloat(actionsStore.selectedRows[0]?.multa ?? 0),
                  2
                )
              "
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="desconto" label-for="desconto" label="Desconto R$">
            <b-form-input
              :value="
                numberToReal(
                  parseFloat(actionsStore.selectedRows[0]?.desconto ?? 0),
                  2
                )
              "
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="border-bottom">
        <b-col md="3">
          <b-form-group
            id="valor_total"
            label-for="valor_total"
            label="Vlr. Receber R$"
          >
            <b-form-input
              :value="
                numberToReal(
                  parseFloat(actionsStore.selectedRows[0]?.valor_total ?? 0),
                  2
                )
              "
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-form-group
            id="dataVencimento"
            label-for="dataVencimento"
            label="Data Vencimento"
          >
            <b-form-datepicker
              v-model="dataVencimentoParcial"
              locale="pt-br"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="dataRecebimento"
            label-for="dataRecebimento"
            label="Data Recebimento"
          >
            <b-form-datepicker
              v-model="dataRecebimentoParcial"
              locale="pt-br"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="bancoRecebimento"
            label-for="bancoRecebimento"
            label="Conta"
            class="mr-5"
          >
            <b-form-select v-model="contaParcial">
              <b-form-select-option
                v-for="b in bancos"
                :key="b.id_banco + 'bancos'"
                :value="b"
              >
                {{ b.nome }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="meioPagamento"
            label-for="meioPagamento"
            label="Meio de pagamento"
          >
            <b-form-select v-model="meioPagamentoParcial">
              <b-form-select-option
                v-for="meio in meiosPagamentos"
                :key="meio.id + 'meio'"
                :value="meio"
              >
                {{ meio.descricao }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Valor Recebido R$" label-for="valor_parcial">
            <money
              class="form-control"
              id="valor_parcial"
              name="valor_parcial"
              v-model="valorParcial"
              v-bind="configMask"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="valorParcial > 0">
        <b-col v-if="!descontoParcial">
          Você irá gerar uma nova conta à receber no valor de
          <span class="font-weight-bold text-primary"
            >R${{ numberToReal(parcialValueToDisplay - valorParcial) }}</span
          >
          ?
        </b-col>
        <b-col v-else>
          Você deseja gerar desconto no valor de
          <span class="font-weight-bold text-primary"
            >R${{ numberToReal(parcialValueToDisplay - valorParcial, 2) }}</span
          >
          ?
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="receberParcial"
            ref="receber_parcial"
            class="mr-5"
          >
            Receber
          </b-button>
          <b-button
            @click="
              {
                resetParcialData();
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="menuAcoes" title="Recebimento em massa" centered size="xl">
      <b-form class="form">
        <div class="d-flex" style="max-height: 450px; overflow: auto">
          <b-table
            v-if="actionsStore.selectedRows.length > 0"
            striped
            small
            hover
            :items="recebimentoTableItems"
            :fields="actionTableFields"
          >
            <template #cell(actions)="row">
              <button
                type="button"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip.hover.bottom="'Excluir'"
                @click="() => handleRemoveContaAReceber(row)"
              >
                <TrashCanIcon :size="16" />
              </button>
            </template>
          </b-table>
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex">
          <DatePickerInput
            label="Data de recebimento"
            @valueUpdated="
              (date) => {
                dataRecebimento = date;
              }
            "
            mode="single"
          />
          <b-form-group
            id="bancoRecebimento"
            label-for="bancoRecebimento"
            label="Conta"
            class="mr-5"
          >
            <b-form-select v-model="bancoRecebimento">
              <b-form-select-option
                v-for="b in bancos"
                :key="b.id_banco + 'bancos'"
                :value="b"
              >
                {{ b.nome }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="meioPagamento"
            label-for="meioPagamento"
            label="Meio de pagamento"
          >
            <b-form-select v-model="meioPagamentoRecebimento">
              <b-form-select-option
                v-for="meio in meiosPagamentos"
                :key="meio.id + 'meio'"
                :value="meio"
              >
                {{ meio.descricao }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Total do recebimento R$"
            label-for="totalRecebimento"
            class="ml-5"
          >
            <money
              class="form-control"
              id="totalRecebimento"
              name="totalRecebimento"
              :value="totalRecebimento"
              disabled
              v-bind="configMask"
            >
            </money>
          </b-form-group>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="receberContas"
            ref="receber_contas"
            class="mr-5"
          >
            Receber
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Contas a Receber"
      acaoBtn="/#/contasReceber/novo"
      textoBtn="Nova"
      api-url="/contareceber"
      :fields="fields"
      classFilterColumn="historico"
      ref="contaReceber"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="documento" v-if="props.field == 'documento'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  href="#"
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.documento }}</a
                >
                <span class="text-muted font-weight-bold d-block">{{
                  props.rowData.historico
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="cliente" v-if="props.field == 'cliente'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.cliente
              }}</span>
            </div>
          </span>
        </span>
        <span slot="data_vencimento" v-if="props.field == 'data_vencimento'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.data_vencimento) }}
          </div>
        </span>
        <span slot="data_recebimento" v-if="props.field == 'data_recebimento'">
          <div
            class="font-weight-bold text-muted"
            v-if="props.rowData.data_recebimento != null"
          >
            {{ formatDate(props.rowData.data_recebimento) }}
          </div>
        </span>
        <span slot="created_at" v-if="props.field == 'created_at'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.created_at) }}
          </div>
        </span>
        <span slot="valor" v-if="props.field == 'valor'">
          <div
            v-if="props.rowData.valor_total > 0"
            v-b-tooltip="
              'Conta está calculando Juros: Valor Orignal + Juros + Multa - Desconto'
            "
          >
            <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >R$
              {{ numberToReal(props.rowData.valor_total, 2) }}
            </span>
            <span
              class="text-muted font-weight-bold d-block"
              style="font-size: 12px"
            >
              R$ {{ numberToReal(props.rowData.valor, 2) }} +
              {{ numberToReal(props.rowData.juros, 2) }} +
              {{ numberToReal(props.rowData.multa, 2) }} -
              {{ numberToReal(props.rowData.desconto, 2) }}
            </span>
          </div>
          <span
            class="text-dark-75 font-weight-bolder d-block font-size-lg"
            v-else
            >R$
            {{ numberToReal(props.rowData.valor, 2) }}
          </span>
        </span>
        <span slot="meio_pagamento" v-if="props.field == 'meio_pagamento'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.meio_pagamento
              }}</span>
            </div>
          </span>
        </span>
        <span slot="status" v-if="props.field == 'status'">
          <div v-html="onRowStatus(props.rowData)"></div>
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  v-if="!props.rowData.data_recebimento"
                  :to="{
                    name: 'contasReceberEditar',
                    params: {
                      id: props.rowData.id,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  v-if="
                    props.rowData.url_boleto &&
                    !props.rowData.data_recebimento &&
                    props.rowData.status_boleto != 'request_cancel'
                  "
                  @click="visualizarBoleto(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Visualizar Boleto'"
                >
                  <Download :size="16" />
                </button>
                <button
                  v-if="
                    (!props.rowData.data_recebimento &&
                      !props.rowData.receipt_id &&
                      !props.rowData.url_boleto) ||
                    props.rowData.status_boleto == 'request_cancel'
                  "
                  @click="gerarBoleto(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Gerar Boleto'"
                >
                  <Boleto :size="16" />
                </button>
                <button
                  v-if="
                    props.rowData.zoop_boleto_id &&
                    !props.rowData.data_recebimento &&
                    props.rowData.status_boleto != 'request_cancel' &&
                    !props.rowData.receipt_id
                  "
                  @click="enviarBoletoEmail(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Enviar Boleto por E-mail'"
                >
                  <EmailSend :size="16" />
                </button>
                <button
                  v-if="
                    props.rowData.zoop_boleto_id &&
                    !props.rowData.data_recebimento &&
                    props.rowData.status_boleto != 'request_cancel'
                  "
                  @click="cancelarBoleto(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Cancelar Boleto'"
                >
                  <Cancel :size="16" />
                </button>
                <button
                  v-if="
                    props.rowData.id_empresa && !props.rowData.data_recebimento
                  "
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
    <Chart endpoint="/contareceber/total" modulo="contareceber" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import EmailSend from "vue-material-design-icons/EmailSend.vue";
import Download from "vue-material-design-icons/Download.vue";
import Boleto from "vue-material-design-icons/CurrencyBrl.vue";
import Cancel from "vue-material-design-icons/Cancel.vue";
import swal from "sweetalert";
import Chart from "@/components/Charts/Charts.vue";
import DatePickerInput from "@/components/DatePickerInput/index.vue";
import FilterFields from "@/components/FilterFields";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { filtersStore } from "@/components/FilterFields/filtersStore";

import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-contareceber",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    EmailSend,
    Download,
    Boleto,
    Cancel,
    Chart,
    FilterFields,
    Menu,
    Money,
    DatePickerInput,
  },
  data() {
    return {
      actionsStore,
      filtersStore,
      valorParcial: 0,
      dataRecebimentoParcial: null,
      dataVencimentoParcial: null,
      meioPagamentoParcial: null,
      contaParcial: null,
      descontoParcial: false,
      configMask: settingMoney,
      resumo: {},
      meioPagamentoRecebimento: null,
      meioPagamentos: [],
      bancoRecebimento: null,
      showTooltipMenu: false,
      dataRecebimento: null,
      bancos: [
        {
          id_banco: 99999,
          nome: "Movimento Caixa",
        },
      ],
      fields: [
        {
          label: "Documento",
          field: "documento",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          slot: true,
          sortable: false,
          width: "20%",
        },
        {
          label: "Emissão",
          field: "created_at",
          type: "date",
          filterable: true,
          slot: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          width: "8%",
        },
        {
          label: "Vencimento",
          field: "data_vencimento",
          type: "date",
          filterable: true,
          slot: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          width: "8%",
        },
        {
          label: "Recebido",
          field: "data_recebimento",
          type: "date",
          slot: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          width: "8%",
        },
        {
          label: "Valor",
          field: "valor",
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "M.Pag",
          field: "meio_pagamento",
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "30%",
        },
      ],
      actionTableFields: [
        {
          key: "documento",
          label: "Documento",
        },
        {
          key: "cliente",
          label: "Cliente",
        },
        {
          key: "vencimento",
          label: "Vencimento",
        },
        {
          key: "recebimento",
          label: "Recebimento",
        },
        {
          key: "valor",
          label: "Valor R$",
        },
        {
          key: "juros",
          label: "Juros R$",
        },
        {
          key: "multa",
          label: "Multa R$",
        },
        {
          key: "desconto",
          label: "Desconto R$",
        },
        {
          key: "valor_total",
          label: "Vlr. Receber R$",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      boletoEvent: null,
    };
  },
  watch: {
    boletoEvent(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel("boletoChannel");
      }
    },
    "filtersStore.filterOptionSelected": {
      handler(value) {
        switch (value.id) {
          case 1:
            this.getCurrentDayData("contasReceber");
            break;
          case 2:
            this.getYesterdayData("contasReceber");
            break;
          case 3:
            this.getCurrentWeekData("contasReceber");
            break;
          case 4:
            this.getLastWeekData("contasReceber");
            break;
          case 5:
            this.getMonthData("contasReceber");
            break;
          default:
            break;
        }
      },
      deep: true,
    },
    valorParcial(newValue) {
      let valorTotalContaReceber = parseFloat(
        this.actionsStore.selectedRows[0].valor
      );
      if (parseFloat(this.actionsStore.selectedRows[0].valor_total) > 0) {
        valorTotalContaReceber = this.actionsStore.selectedRows[0].valor_total;
      }
      if (newValue > valorTotalContaReceber) {
        return (this.valorParcial = valorTotalContaReceber);
      }
      return (this.valorParcial = newValue);
    },
  },
  methods: {
    async getDataInit() {
      const bancos = await this.getBancosByEmpresa();
      this.bancos.push(...bancos);
      this.bancoRecebimento = this.bancos[0];
      const response = await this.$http.post("/financeiro/meios-pagamento");
      this.meiosPagamentos = response.data;
      this.meioPagamentoRecebimento = this.meiosPagamentos[0];
    },
    resetParcialData() {
      this.dataRecebimentoParcial = null;
      this.dataVencimentoParcial = null;
      this.descontoParcial = false;
      this.contaParcial = null;
      this.meioPagamentoParcial = null;
      this.valorParcial = 0;
      this.actionsStore.updateTable = !this.actionsStore.updateTable;
      this.actionsStore.selectedRows = [];
    },
    handleRemoveContaAReceber({ index }) {
      this.actionsStore.selectedRows = this.actionsStore.selectedRows.filter(
        (row, rowIndex) => index !== rowIndex
      );
    },
    async receberParcial() {
      const contaReceberData = this.actionsStore.selectedRows[0];

      if (
        !this.dataRecebimentoParcial ||
        !this.contaParcial ||
        !this.meioPagamentoParcial ||
        !this.valorParcial
      ) {
        this.$toast.error("Preencha todos os campos necessários!");
        return;
      }
      this.addLoadingToButton("receber_parcial");
      try {
        const { data: dataToSend } = await this.$http.post(
          "/contareceber/receber-parcial",
          {
            id_conta_receber: contaReceberData.id,
            valor_parcial: this.valorParcial,
          }
        );
        const response = await this.$http.post("/contareceber/create-parcial", {
          ...dataToSend,
          data_vencimento: this.dataVencimentoParcial,
          data_recebimento: this.dataRecebimentoParcial,
          id_plano_contas: this.contaParcial.id,
          id_meio_pagamento: this.meioPagamentoParcial.id,
          valor_parcial: this.valorParcial,
          desconto: this.descontoParcial,
        });
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("receber_parcial");
      this.resetParcialData();
      this.$bvModal.hide("receberParcial");
    },
    async verificaContasReceber() {
      await this.$http
        .post("/boletos/webhook-transacations")
        .then(() => {
          window.Echo.channel("boletoChannel").listen(
            ".boletoEvent",
            (message) => {
              if (message) {
                this.boletoEvent = message.message;
                this.$refs.contaReceber.refresh();
              }
            }
          );
        })
        .catch(() => {});
    },
    async receberContas() {
      this.addLoadingToButton("receber_contas");
      try {
        await this.$http.post("/contareceber/recebimento", {
          id_banco: this.bancoRecebimento.id_banco,
          id_meio_pagamento: this.meioPagamentoRecebimento.id,
          data_recebimento: this.dataRecebimento,
          contas_receber: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.meioPagamentoRecebimento = null;
        this.bancoRecebimento = null;
        this.$toast.success("Contas recebidas com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
      } catch (error) {
        console.log(error);
        this.meioPagamentoRecebimento = null;
        this.bancoRecebimento = null;
        this.$bvModal.hide("menuAcoes");
      }
      this.removeLoadingToButton("receber_contas");
    },
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);
      return (data =
        Date.parse(data) >= startDate && Date.parse(data) <= endDate);
    },
    onRowStatus(dataItem, onlyLabel = false) {
      if (
        dataItem.data_vencimento < this.thisDate() &&
        dataItem.data_recebimento === null
      ) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-danger label-inline">Vencida</span>'
          : "Vencida";
      }
      if (
        dataItem.data_vencimento > this.thisDate() &&
        dataItem.data_recebimento === null
      ) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-warning label-inline">A receber</span>'
          : "A receber";
      }
      if (dataItem.data_recebimento !== null) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-success label-inline">Recebida</span>'
          : "Recebida";
      }
      return "";
    },
    gerarBoleto(data) {
      if (!this.nutsDigital) {
        this.$toast.warning("Configure a sua conta Nuts Digital!");
        return;
      }
      swal({
        title:
          "Deseja gerar o boleto referente a conta: " + data.historico + "?",
        text: "Aguarde, pois o link será gerado automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/boletos/create", {
            id_conta_receber: data.id,
          })
          .then((result) => {
            if (result["data"]["url_boleto"]) {
              window.open(result["data"]["url_boleto"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Boleto gerado com sucesso!");
            this.$refs.contaReceber.refresh();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    enviarBoletoEmail(data) {
      if (!this.nutsDigital) {
        this.$toast.warning("Configure a sua conta Nuts Digital!");
        return;
      }
      this.$swal
        .fire({
          icon: "warning",
          title:
            "Deseja visualizar o boleto referente a conta: " +
            data.historico +
            "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/boletos/enviar-email", {
              id_conta_receber: data.id,
            });
            this.$refs.contaReceber.refresh();
            this.$toast.success("Boleto enviado com sucesso!");
          }
        });
    },
    cancelarBoleto(data) {
      this.getRequestAdmin(() => {
        if (!this.nutsDigital) {
          this.$toast.warning("Configure a sua conta Nuts Digital!");
          return;
        }
        this.$swal
          .fire({
            icon: "warning",
            title:
              "Deseja cancelar o boleto referente a conta: " +
              data.historico +
              "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/boletos/cancel", {
                id_conta_receber: data.id,
              });
              this.$refs.contaReceber.refresh();
              this.$toast.success("Boleto cancelado com sucesso!");
            }
          });
      });
    },
    visualizarBoleto(data) {
      if (!this.nutsDigital) {
        this.$toast.warning("Configure a sua conta Nuts Digital!");
        return;
      }
      this.$swal
        .fire({
          icon: "warning",
          title:
            "Deseja visualizar o boleto referente a conta: " +
            data.historico +
            "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (data.url_boleto) {
              window.open(data.url_boleto, "_blank");
            }
            this.$refs.contaReceber.refresh();
            this.$toast.success("Boleto visualizado com sucesso!");
          }
        });
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o lançamento: " + data.historico + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/contareceber/delete", {
                id: data.id,
              });
              this.$refs.contaReceber.refresh();
              this.$toast.success("Lançamento excluído!");
            }
          });
      });
    },
    async estornar() {
      this.addLoadingToButton("estornar_contas");
      try {
        await this.$http.post("/contareceber/estornar", {
          contas_receber: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Contas estornadas com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("estornarContas");
      } catch (error) {
        this.$bvModal.hide("estornarContas");
      }
      this.removeLoadingToButton("estornar_contas");
    },
    async gerarCarneBoletoReduzido() {
      this.addLoadingToButton("gerarCarneBoleto");
      try {
        await this.$http
          .post("/contareceber/carne-reduzido", {
            contas_receber: this.actionsStore.selectedRows.map((conta) => ({
              id: conta.id,
            })),
          })
          .then((result) => {
            if (result["data"]["carne"]) {
              this.actionsStore.selectedRows = [];
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
              this.$toast.success("Carnê gerado com sucesso!");
              this.$refs.contaReceber.refresh();
              this.$bvModal.hide("menuAcoes");
              this.$bvModal.hide("gerarCarneBoleto");
              window.open(result["data"]["carne"], "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Carnê gerado com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("gerarCarneBoleto");
      } catch (error) {
        this.$bvModal.hide("gerarCarneBoleto");
      }
      this.removeLoadingToButton("gerarCarneBoleto");
    },
    async gerarCarne() {
      this.addLoadingToButton("gerarCarne");
      try {
        await this.$http
          .post("/contareceber/carne", {
            contas_receber: this.actionsStore.selectedRows.map((conta) => ({
              id: conta.id,
            })),
          })
          .then((result) => {
            if (result["data"]["carne"]) {
              this.actionsStore.selectedRows = [];
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
              this.$toast.success("Carnê gerado com sucesso!");
              this.$refs.contaReceber.refresh();
              this.$bvModal.hide("menuAcoes");
              this.$bvModal.hide("gerarCarne");
              window.open(result["data"]["carne"], "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Carnê gerado com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("gerarCarne");
      } catch (error) {
        this.$bvModal.hide("gerarCarne");
      }
      this.removeLoadingToButton("gerarCarne");
    },
    async gerarCarneLoja() {
      this.addLoadingToButton("gerarCarneLoja");
      try {
        await this.$http
          .post("/contareceber/carne-loja", {
            contas_receber: this.actionsStore.selectedRows.map((conta) => ({
              id: conta.id,
            })),
          })
          .then((result) => {
            if (result["data"]["carne"]) {
              this.actionsStore.selectedRows = [];
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
              this.$toast.success("Carnê gerado com sucesso!");
              this.$refs.contaReceber.refresh();
              this.$bvModal.hide("menuAcoes");
              this.$bvModal.hide("gerarCarneLoja");
              window.open(result["data"]["carne"], "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Carnê gerado com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("gerarCarneLoja");
      } catch (error) {
        this.$bvModal.hide("gerarCarneLoja");
      }
      this.removeLoadingToButton("gerarCarneLoja");
    },
    async gerarRecibo() {
      this.addLoadingToButton("gerarRecibo");
      try {
        await this.$http
          .post("/contareceber/recibo", {
            id_conta_receber: this.actionsStore?.selectedRows[0]?.id,
          })
          .then((result) => {
            if (result["data"]["url_recibo"]) {
              this.actionsStore.selectedRows = [];
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
              this.$toast.success("Recibo gerado com sucesso!");
              this.$refs.contaReceber.refresh();
              this.$bvModal.hide("menuAcoes");
              this.$bvModal.hide("gerarRecibo");
              window.open(result["data"]["url_recibo"], "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Recibo gerado com sucesso!");
        this.$refs.contaReceber.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("gerarRecibo");
      } catch (error) {
        this.$bvModal.hide("gerarRecibo");
      }
      this.removeLoadingToButton("gerarRecibo");
    },
    async marcar() {
      this.addLoadingToButton("marcarContas");
      try {
        await this.$http.post("/contareceber/marcar", {
          contas_receber: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Contas marcadas com sucesso!");
        this.$refs.contaPagar.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("marcarContas");
      } catch (error) {
        this.$bvModal.hide("marcarContas");
      }
      this.removeLoadingToButton("marcarContas");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "nutsDigital"]),
    config() {
      return this.layoutConfig();
    },
    parcialValueToDisplay() {
      if (actionsStore.selectedRows.length === 0) {
        return 0;
      }
      if (actionsStore.selectedRows[0].valor_total > 0) {
        return parseFloat(actionsStore.selectedRows[0].valor_total);
      }
      return parseFloat(actionsStore.selectedRows[0].valor);
    },
    recebimentoTableItems() {
      if (actionsStore.selectedRows.length === 0) return [];
      return actionsStore.selectedRows
        .map((row) => ({
          documento: row.documento,
          cliente: row.cliente,
          vencimento: row.data_vencimento,
          recebimento: row.data_recebimento,
          valor: this.numberToReal(row.valor, 2),
          juros: this.numberToReal(row.juros, 2),
          multa: this.numberToReal(row.multa, 2),
          desconto: this.numberToReal(row.desconto, 2),
          valor_total: this.numberToReal(row.valor_total, 2),
          status: this.onRowStatus(row, true),
        }))
        .filter((item) => item.status !== "Recebida");
    },
    totalRecebimento() {
      if (actionsStore.selectedRows.length === 0) return 0;
      return actionsStore.selectedRows.reduce(
        (acc, row) =>
          acc +
          parseFloat(row.valor) +
          parseFloat(row.juros) +
          parseFloat(row.multa) -
          parseFloat(row.desconto),
        0
      );
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();

    this.verificaContasReceber();
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

#menuAcoes___BV_modal_footer_ {
  display: flex;
  justify-content: space-between;
}
</style>
