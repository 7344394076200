<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <img src="media/logos/logo_nuts_1.png" class="max-h-100px" />
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg pt-20"
                >
                  Bem vindo
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Não possui conta?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Cadastre-se</a
                  ></span
                >
              </div>
              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="login-email"
                  name="email"
                  ref="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">
                  E-mail inválido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Esqueceu a senha?</a
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo senha é obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitConta()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Cadastre-se
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Informe os dados para criar sua conta
                </p>
                <p class="text-muted font-weight-bold font-size-h4">
                  Conheça a plataforma por 5 dias grátis!
                </p>
              </div>

              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Nome</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-nome"
                  name="nome"
                  autocomplete="off"
                  placeholder="Seu nome"
                  ref="nome"
                  v-model="$v.conta.nome.$model"
                  :state="validadeStateConta('nome')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-email"
                  name="conta-email"
                  autocomplete="off"
                  ref="conta-email"
                  placeholder="Seu e-mail"
                  v-model="$v.conta.email.$model"
                  :state="validadeStateConta('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="login-fone" label="" label-for="login-fone">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Telefone</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-fone"
                  name="conta-fone"
                  autocomplete="off"
                  ref="conta-fone"
                  placeholder="Seu Telefone"
                  v-model="$v.conta.fone.$model"
                  :state="validadeStateConta('fone')"
                  v-mask="['(##) #### ####', '(##) # #### ####']"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="login-email" label="" label-for="login-email">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Empresa</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="conta-nome_empresa"
                  name="nome_empresa"
                  autocomplete="off"
                  ref="nome_empresa"
                  placeholder="Nome da empresa"
                  v-model="$v.conta.nome_empresa.$model"
                  :state="validadeStateConta('nome_empresa')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="conta-senha"
                  name="conta-senha"
                  autocomplete="off"
                  v-model="$v.conta.password.$model"
                  :state="validadeStateConta('password')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" v-model="conta.termos" />
                  <span class="mr-2"></span>
                  Aceito com os
                  <a href="#/termos" target="_blank" class="ml-2"
                    >termos e condições</a
                  >.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_submit_conta"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Salvar
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-forgot">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              @submit.stop.prevent="onSubmitResetConta()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Esqueceu a senha?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Insira seu e-mail para redefinir sua Senha
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >E-mail</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  id="email"
                  name="email"
                  ref="email"
                  v-model="$v.reset.email.$model"
                  :state="validadeStateReset('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo obrigatório.
                </b-form-invalid-feedback>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN, REGISTER } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { httpResetLogin } from "../../../configurations/axios";
import { mask } from "vue-the-mask";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      conta: {
        nome: "",
        nome_empresa: "",
        email: "",
        password: "",
        fone: "",
        termos: false,
      },
      reset: {
        email: "",
      },
    };
  },
  directives: {
    mask,
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
    conta: {
      nome: {
        required,
      },
      nome_empresa: {
        required,
      },
      email: {
        required,
        email,
      },
      fone: {
        required,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
    reset: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validadeStateConta(name) {
      const { $dirty, $error } = this.$v.conta[name];
      return $dirty ? !$error : null;
    },
    validadeStateReset(name) {
      const { $dirty, $error } = this.$v.reset[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmitLogin() {
      const submitButton = this.$refs["kt_login_submit"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        const email = this.$v.form.email.$model;
        const password = this.$v.form.password.$model;

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        submitButton.setAttribute("disabled", "");

        await this.$store.dispatch(LOGIN, { email, password });
        this.$toast.success("Logado com sucesso!");

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    async onSubmitConta() {
      const submitButton = this.$refs["kt_login_submit_conta"];

      try {
        this.$v.conta.$touch();

        if (this.$v.conta.$anyError) {
          return;
        }

        if (!this.conta.termos) {
          this.$toast.warning("Verifique os termos de uso!");
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        await this.$store.dispatch(REGISTER, this.conta);

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    async onSubmitResetConta() {
      const submitButton = this.$refs["kt_login_forgot_submit"];

      try {
        this.$v.reset.$touch();

        if (this.$v.reset.$anyError) {
          return;
        }

        const email = this.$v.reset.email.$model;
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        await httpResetLogin.post("usuario/reset-password", {
          email: email,
        });

        this.$toast.success("Verifique no seu e-mail e siga o procedimento!");
        return submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    KTUtil.getById("kt_login_signin_form");
    KTUtil.getById("kt_login_signup_form");
    KTUtil.getById("kt_login_forgot_form");
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
