<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: var(--primary-color)"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo_nuts.png"
              class="max-h-100px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #fff"
          >
            Conheça <br />a melhor ferramenta de gestão para sua empresa.
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          style="background-color: var(--primary-color);"
        ></div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Cadastre sua senha
                </h3>
              </div>
              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo senha é obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Repita a Senha</label
                  >
                </div>
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.repeatpassword.$model"
                  :state="validateState('repeatpassword')"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  Campo senha é obrigatório.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Cadastrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { httpResetLogin } from "../../../configurations/axios";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      state: "signin",
      form: {
        password: "",
        repeatpassword: ""
      },
      conta: {
        token: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3)
      },
      repeatpassword: {
        required,
        minLength: minLength(3)
      }
    },
    conta: {
      password: {
        required,
        minLength: minLength(3)
      },
      repeatpassword: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validadeStateConta(name) {
      const { $dirty, $error } = this.$v.conta[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        repeatpassword: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmitLogin() {
      const submitButton = this.$refs["kt_login_submit"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        const password = this.$v.form.password.$model;
        const repeatpassword = this.$v.form.repeatpassword.$model;

        if (password != repeatpassword) {
          this.$toast.error("Senhas não conferem, verifique!");
          submitButton.removeAttribute("disabled", "");
          return submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await httpResetLogin.post("usuario/update-password", {
          token: this.$route.params.mailtoken,
          password: repeatpassword
        });

        this.$toast.success("Realize o login!");
        this.$router.push({ name: "login" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    KTUtil.getById("kt_login_signin_form");
    KTUtil.getById("kt_login_signup_form");
    KTUtil.getById("kt_login_forgot_form");
  }
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
