<template>
  <Modal :value="open" :onClose="close" :close="false">
    <b-tabs content-class="mt-3">
      <b-tab title="Editando Item" active>
        <b-row class="form-group">
          <b-col class="busca" md="6">
            <Input
              ref="descricao"
              label="Descrição"
              id="descricao"
              @keypress.enter.prevent="save"
              labelBold
              v-model="valueProduto.descricao"
            />
          </b-col>
          <b-col>
            <b-form-group label="Quantidade" label-for="quantidade">
              <money
                class="form-control"
                id="quantidade"
                name="quantidade"
                v-model="valueProduto.quantidade"
                v-bind="quantityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Valor Unitário" label-for="preco">
              <money
                class="form-control"
                id="preco"
                name="preco"
                v-model="valueProduto.price"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Desconto" label-for="desconto">
              <money
                class="form-control"
                id="desconto"
                name="desconto"
                v-model="valueProduto.desconto"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="CST/CSOSN Não contribuinte"
              label-for="form.id_cst_csosn"
            >
              <VueSelect
                placeholder="Digite o código para pesquisar"
                label="codigo"
                @input="setCSTCSOSN"
                v-model="cstcsosn"
                :options="cstcsosns"
              >
                <template slot="no-options">
                  Informe o código para buscar
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.codigo + " - " + option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.codigo }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="CST/CSOSN Contribuinte"
              label-for="form.id_cst_csosn"
            >
              <VueSelect
                placeholder="Digite o código para pesquisar"
                label="codigo"
                @input="setCSTCSOSNContribuinte"
                v-model="cstcsosnContribuinte"
                :options="cstcsosns"
              >
                <template slot="no-options">
                  Informe o código para buscar
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.codigo + " - " + option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.codigo }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="CFOP" label-for="form.id_cfop">
              <VueSelect
                placeholder="Digite o código para pesquisar"
                ref="buscarCliente"
                :filterable="false"
                v-model="cfop"
                @input="setCFOP"
                :options="cfops"
                @search="onSearchCFOP"
              >
                <template slot="no-options">
                  Informe o código do CFOP
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.codigo }} - {{ option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.codigo }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="NCM" label-for="form.id_ncm">
              <VueSelect
                placeholder="Digite o código para pesquisar"
                label="codigo"
                @input="setNCM"
                v-model="ncm"
                :options="ncms"
                @search="onSearchNCM"
              >
                <template slot="no-options"> Informe o código do NCM </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.codigo + " - " + option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.codigo }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="MVA" label-for="mva">
              <money
                class="form-control"
                id="mva"
                name="mva"
                v-model="valueProduto.mva"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group label="Número Pedido" label-for="pedido">
              <b-input
                maxlength="13"
                class="form-control"
                id="pedido"
                name="pedido"
                v-model="valueProduto.pedido"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Número Item" label-for="numeroItem">
              <b-input
                maxlength="6"
                class="form-control"
                id="numeroItem"
                name="numeroItem"
                v-model="valueProduto.numeroItem"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Origem" label-for="origem">
              <b-input
                class="form-control"
                id="origem"
                v-model="valueProduto.origem"
            /></b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Total de Item R$" label-for="total">
              <money
                class="form-control"
                id="total"
                name="total"
                disabled
                v-model="totalProduto"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="observacao"
              label="Observação"
              label-for="observacao"
            >
              <b-form-textarea
                id="observacao"
                name="observacao"
                placeholder=""
                v-model="valueProduto.informacoesAdicionais"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              id="calculo_manual"
              label-for="calculo_manual"
              label="Cálculo Manual"
            >
              <span class="switch switch-icon">
                <label>
                  <input
                    type="checkbox"
                    :value="true"
                    @change="setCalculo"
                    :checked="calculoAutomatico"
                  />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col> </b-row
      ></b-tab>
      <b-tab title="Totais" :disabled="!calculoAutomatico">
        <b-row class="form-group">
          <b-col class="busca" md="6">
            <Input
              ref="descricao"
              label="Descrição"
              id="descricao"
              @keypress.enter.prevent="save"
              labelBold
              v-model="valueProduto.descricao"
              disabled
            />
          </b-col>
          <b-col>
            <b-form-group label="Quantidade" label-for="quantidade">
              <money
                class="form-control"
                id="quantidade"
                name="quantidade"
                v-model="valueProduto.quantity"
                disabled
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Total do Item R$" label-for="totalProd">
              <money
                class="form-control"
                id="totalProd"
                name="totalProd"
                v-model="totalProduto"
                v-bind="configMask"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="% Aliq ICMS ST" label-for="pICMSST">
              <money
                class="form-control"
                id="pICMSST"
                name="pICMSST"
                v-model="pICMSST"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="BC ICMS ST %" label-for="baseICMSST">
              <money
                class="form-control"
                id="baseICMSST"
                name="baseICMSST"
                v-model="baseICMSST"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Valor ICMS ST R$" label-for="valorICMSST">
              <money
                class="form-control"
                id="valorICMSST"
                name="valorICMSST"
                v-model="valorICMSST"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="% Aliq ICMS" label-for="pICMS">
              <money
                class="form-control"
                id="pICMS"
                name="pICMS"
                v-model="pICMS"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="BC ICMS R$" label-for="baseICMS">
              <money
                class="form-control"
                id="baseICMS"
                name="baseICMS"
                v-model="baseICMS"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Valor ICMS R$" label-for="valorICMS">
              <money
                class="form-control"
                id="valorICMS"
                name="valorICMS"
                v-model="valorICMS"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Valor IPI R$" label-for="vIPI">
              <money
                class="form-control"
                id="vIPI"
                name="vIPI"
                v-model="vIPI"
                v-bind="configMask"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="DI" v-if="valueProduto.importacao">
        <b-row class="form-group">
          <b-col md="5">
            <Input
              required
              ref="numero_documento"
              label="Número do Documento de Importação"
              id="descricao"
              labelBold
              v-model="valueProduto.numero_documento_importacao"
            />
          </b-col>
          <b-col md="3">
            <b-form-group label="Data de Registro da" label-for="data_registro">
              <b-input
                required
                type="date"
                class="form-control"
                id="data_registro"
                name="data_registro"
                v-model="valueProduto.data_registro"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Local de Desembaraço"
              label-for="local_desembaraco"
            >
              <b-input
                required
                maxlength="50"
                class="form-control"
                id="local_desembaraco"
                name="local_desembaraco"
                v-model="valueProduto.local_desembaraco"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="UF de Desembaraço" label-for="uf_desembaraco">
              <b-input
                required
                maxlength="2"
                class="form-control"
                id="uf_desembaraco"
                name="uf_desembaraco"
                v-model="valueProduto.uf_desembaraco"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Data Desembaraço" label-for="data_desembaraco">
              <b-input
                type="date"
                class="form-control"
                id="data_desembaraco"
                name="data_desembaraco"
                v-model="valueProduto.data_desembaraco"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Código do exportador"
              label-for="codigo_exportador"
            >
              <b-input
                required
                maxlength="8"
                class="form-control"
                id="codigo_exportador"
                name="codigo_exportador"
                v-model="valueProduto.codigo_exportador"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Número da Adição" label-for="numero_adicao">
              <b-input
                maxlength="3"
                class="form-control"
                id="numero_adicao"
                name="numero_adicao"
                v-model="valueProduto.numero_adicao"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Sequencial de adições"
              label-for="sequencial_adicoes"
            >
              <b-input
                required
                maxlength="3"
                class="form-control"
                id="sequencial_adicoes"
                name="sequencial_adicoes"
                v-model="valueProduto.sequencial_adicoes"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Código do fabricante"
              label-for="codigo_fabricante"
            >
              <b-input
                required
                maxlength="10"
                class="form-control"
                id="codigo_fabricante"
                name="codigo_fabricante"
                v-model="valueProduto.codigo_fabricante"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Valor do desconto"
              label-for="desconto_item_di"
            >
              <money
                class="form-control"
                id="desconto_item_di"
                name="desconto_item_di"
                v-model="valueProduto.desconto_item_di"
                v-bind="quantityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Base Cálculo de Importação"
              label-for="base_importacao"
            >
              <money
                class="form-control"
                id="base_importacao"
                name="base_importacao"
                v-model="valueProduto.base_importacao"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Valor Despesas"
              label-for="despesas_aduaneiras"
            >
              <money
                class="form-control"
                id="despesas_aduaneiras"
                name="despesas_aduaneiras"
                v-model="valueProduto.despesas_aduaneiras"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Valor Imposto Importação"
              label-for="valor_imposto"
            >
              <money
                class="form-control"
                id="valor_imposto"
                name="valor_imposto"
                v-model="valueProduto.valor_imposto"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Valor IOF" label-for="valor_iof">
              <money
                class="form-control"
                id="valor_iof"
                name="valor_iof"
                v-model="valueProduto.valor_iof"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Valor AFRMM" label-for="valor_afrmm">
              <money
                class="form-control"
                id="valor_afrmm"
                name="valor_afrmm"
                v-model="valueProduto.valor_afrmm"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Via de Transporte" label-for="via_transporte">
              <select
                name="via_transporte"
                id="via_transporte"
                v-model="valueProduto.via_transporte"
                class="form-control"
              >
                <option
                  v-for="(v, index) in viaTransportes"
                  :value="v.id"
                  :key="'id' + index"
                >
                  {{ v.descricao }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="CNPJ do Adquirente"
              label-for="cnpj_adquirente"
            >
              <b-input
                required
                maxlength="18"
                class="form-control"
                id="cnpj_adquirente"
                name="cnpj_adquirente"
                v-model="valueProduto.cnpj_adquirente"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="UF do Adquirente" label-for="uf_adquirente">
              <b-input
                maxlength="2"
                class="form-control"
                id="uf_adquirente"
                name="uf_adquirente"
                v-model="valueProduto.uf_adquirente"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Número Drawback" label-for="numero_drawback">
              <b-input
                maxlength="20"
                class="form-control"
                id="numero_drawback"
                name="numero_drawback"
                v-model="valueProduto.numero_drawback"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Forma de Importação"
              label-for="forma_importacao"
            >
              <select
                name="via_transporte"
                id="via_transporte"
                v-model="valueProduto.forma_importacao"
                class="form-control"
              >
                <option
                  v-for="(t, index) in tipoImportacao"
                  :value="t.id"
                  :key="'id' + index"
                >
                  {{ t.descricao }}
                </option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
      <Button
        type="success"
        @click="
          () => {
            save();
            close();
          }
        "
        >Gravar</Button
      >
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button, Input } from "@/components/Form";
import { Money } from "v-money";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import VueSelect from "vue-select";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import { debounce } from "loadsh";
import "vue-select/dist/vue-select.css";

export default {
  name: "ModalProduto",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Input,
    Money,
    VueSelect,
  },
  data() {
    return {
      clientes: [],
      valueProduto: {},
      configMask: settingMoney,
      priceOptions,
      quantityOptions,
      cstcsosn: null,
      cstcsosnContribuinte: null,
      cstcsosns: [],
      cfop: null,
      cfops: [],
      ncm: null,
      ncms: [],
      calculoAutomatico: false,
      pICMS: 0,
      pICMSST: 0,
      baseICMSST: 0,
      valorICMSST: 0,
      baseICMS: 0,
      valorICMS: 0,
      vIPI: 0,
      tipoImportacao: [
        {
          id: 1,
          descricao: "Por conta própria",
        },
        {
          id: 2,
          descricao: "Importação por conta e ordem",
        },
        {
          id: 3,
          descricao: "Importação por encomenda",
        },
      ],
      viaTransportes: [
        {
          id: 1,
          descricao: "Marítima",
        },
        {
          id: 2,
          descricao: "Fluvial",
        },
        {
          id: 3,
          descricao: "Lacustre",
        },
        {
          id: 4,
          descricao: "Aérea",
        },
        {
          id: 5,
          descricao: "Postal",
        },
        {
          id: 6,
          descricao: "Ferroviária",
        },
        {
          id: 7,
          descricao: "Rodoviária",
        },
        {
          id: 8,
          descricao: "Conduto",
        },
        {
          id: 9,
          descricao: "Meios Próprios",
        },
        {
          id: 10,
          descricao: "Entrada / Saída ficta",
        },
      ],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    changeProduto: {
      type: Function,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  watch: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueProduto = newValue;
        this.setCFOP(this.produto.cfop);
      }
    },
  },
  mounted() {
    this.valueProduto = this.produto;
    this.cstcsosn = this.valueProduto.cst_csosn;
    this.cstcsosnContribuinte = this.valueProduto.cst_csosn_contribuinte;
    this.cfop = this.valueProduto.cfop;
    this.ncm = this.valueProduto.ncm;
    this.getDataInit();
  },
  methods: {
    save() {
      if (this.baseICMSST) {
        this.valueProduto.totais.baseICMSST = this.baseICMSST;
      }

      if (this.pICMS) {
        this.valueProduto.totais.pICMS = this.pICMS;
      }

      if (this.pICMSST) {
        this.valueProduto.totais.pICMSST = this.pICMSST;
      }

      if (this.valorICMSST) {
        this.valueProduto.totais.valorICMSST = this.valorICMSST;
      }

      if (this.baseICMS) {
        this.valueProduto.totais.baseICMS = this.baseICMS;
      }

      if (this.valorICMS) {
        this.valueProduto.totais.valorICMS = this.valorICMS;
      }

      if (this.vIPI) {
        this.valueProduto.totais.vIPI = this.vIPI;
      }

      this.valueProduto = {
        ...this.valueProduto,
        cst_csosn: this.cstcsosn,
        cfop: this.cfop,
        ncm: this.ncm,
        cst_csosn_contribuinte: this.cstcsosnContribuinte,
      };
      this.changeProduto(this.valueProduto);
      this.$toast.success("Produto editado com sucesso!");
    },
    async getDataInit() {
      this.getCstCsosn().then((data) => (this.cstcsosns = data));
    },
    setCSTCSOSNContribuinte(cstcsosn) {
      this.cstcsosnContribuinte = cstcsosn;
    },
    setCSTCSOSN(cstcsosn) {
      this.cstcsosn = cstcsosn;
    },
    setNCM(ncm) {
      this.ncm = ncm;
    },
    setProduto(produto) {
      this.valueProduto = produto;
    },
    setCFOP(cfop) {
      this.cfop = cfop;
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    setCalculo() {
      this.calculoAutomatico = this.calculoAutomatico == true ? false : true;
      this.valueProduto.calculo_manual =
        this.valueProduto.calculo_manual == true ? false : true;
    },
    searchCFOP: debounce(async (loading, search, vm) => {
      vm.cfops = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),
    onSearchNCM(search, loading) {
      if (search.length) {
        loading(true);
        this.searchNCM(loading, search, this);
      }
    },
    searchNCM: debounce(async (loading, search, vm) => {
      vm.ncms = await vm.getNCM(search);
      loading(false);
    }, 350),
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
  },
  computed: {
    totalProduto() {
      return (
        this.valueProduto.quantity * this.valueProduto.price -
        this.valueProduto.desconto
      );
    },
  },
};
</script>
