import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default class ContaPagarData {
  historico = "";
  documento = "";
  valor = 0;
  created_at = moment().format("YYYY-MM-DD");
  data_vencimento = moment().format("YYYY-MM-DD");
  tipo = "";
  pago = false;
  banco = null;
  data_pagamento = null;
  repetir = false;
  vezes = null;
  parcelas = [];
  id_meio_pagamento = null;
  desconto = 0;
  multa = 0;
  juros = 0;
  valor_total = 0;
  obs = "";

  constructor({
    historico = "",
    valor = 0,
    created_at = moment().format("YYYY-MM-DD"),
    data_vencimento = moment().format("YYYY-MM-DD"),
    documento = "",
    pago = false,
    banco = null,
    data_pagamento = null,
    repetir = false,
    vezes = null,
    parcelas = [],
    id_meio_pagamento = null,
    desconto = 0,
    multa = 0,
    juros = 0,
    valor_total = 0,
    obs = "",
  }) {
    this.historico = historico;
    this.valor = valor;
    this.created_at = created_at;
    this.data_vencimento = data_vencimento;
    this.documento = documento;
    this.pago = pago;
    this.banco = banco;
    this.data_pagamento = data_pagamento;
    this.repetir = repetir;
    this.vezes = vezes;
    this.parcelas = parcelas;
    this.id_meio_pagamento = id_meio_pagamento;
    this.desconto = desconto;
    this.multa = multa;
    this.juros = juros;
    this.valor_total = valor_total;
    this.obs = obs;
  }
}

export const ContaPagarDataRequired = {
  historico: {
    required,
  },
  valor: {
    required,
  },
  created_at: {
    required,
  },
  data_vencimento: {
    required,
  },
  documento: {
    required,
  },
};
