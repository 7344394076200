<template>
  <div >
    <label class="d-block" v-if="label == 1" style="color: #ffffff !important; font-size: 15px;"> Qtd: </label>
    <label class="d-block" v-if="label == 2" style="color: #ffffff !important; font-size: 15px;"> Valor: </label>
    <input type="tel" class="form-control" :class="inputClass" :value="formattedValue" :placeholder="placeholder"
      :disabled="disabled" @change="change" @keypress.enter.prevent="hamdleOnEnter" v-money="options" />
  </div>
</template>

<script>
import { VMoney } from "v-money";

export default {
  name: "InputMoney",
  components: {
    // Label,
  },
  directives: {
    money: VMoney,
  },
  props: {
    label: {
      type: String,
    },
    labelProps: {
      type: Object,
    },
    labelBold: {
      type: Boolean,
      default: () => false,
    },
    inputClass: {
      type: String,
    },
    value: {
      required: false,
      type: [Number, String],
      default: 0,
    },
    id: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    onEnter: {
      type: Function,
      default: () => { },
    },
    options: {
      type: Object,
      default: () => ({
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: true,
      }),
    },
  },
  data() {
    return {
      formattedValue: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const formatted = newValue;
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted;
        }
      },
    },
  },
  methods: {
    hamdleOnEnter() {
      this.onEnter();
    },
    change(evt) {
      this.$emit(
        "input",
        this.masked ? evt.target.value : evt.target.value,
        this.precision
      );
    },
  },
};
</script>
