<template>
  <b-tab
    title="Cliente"
    :active="isTheCurrentTab"
    @click="() => (store.currentTab = 0)"
    :disabled="!isTheCurrentTab && !store.isUpdate"
  >
    <b-row>
      <b-col md="8">
        <b-form-group label="Cliente" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            :state="validateClienteState('nome')"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clientes"
            @search="onSearchCliente"
            v-model="form"
          >
            <template slot="no-options"> Informe o nome do cliente </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.nome }}</div>
                <span class="text-muted"
                  >CPF: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-mask="['###.###.###-##', '###.###.###/####-##']"
            v-model="form.cnpj_cpf"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="10" v-if="form?.enderecos">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <b-form-input
            disabled
            id="endereco"
            name="endereco"
            :value="formatEndereco(form?.enderecos[0])"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Solicitante" label-for="solicitante">
          <b-form-input
            id="solicitante"
            name="solicitante"
            v-model="form.solicitante"
            maxlength="50"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Vendedor" label-for="vendedor">
          <VueSelect
            ref="buscaVendedor"
            :filterable="false"
            @input="setVendedor"
            :options="vendedores"
            v-model="vendedor"
            @search="onSearchVendedor"
          >
            <template slot="no-options"> Informe o nome do vendedor </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <button
          ref="add_buyer"
          class="btn btn-primary font-weight-bold"
          v-b-modal.newClient
          style="font-size: 11px; margin-top: 30px"
        >
          Novo Cliente
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4" style="margin-top: 20px" v-if="form.tipo_atacado">
        <span class="label label-lg label-inline label-light-warning"
          >Tipo Atacado</span
        >
      </b-col>
    </b-row>
    <b-modal id="newClient" centered title="Informe os dados">
      <b-row> </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input
              id="nome"
              name="nome"
              v-model="nomeCliente"
              maxlength="50"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
            <b-form-input
              id="cpfCnpjCliente"
              name="cpfCnpjCliente"
              v-mask="['###.###.###-##', '###.###.###/####-##']"
              v-model="cpfCnpjCliente"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
              openModal = false;
            }
          "
        >
          Cancelar
        </b-button>
        <b-button variant="primary" ref="saveClient" @click="saveClient">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { ClienteDataRequired } from "../ClienteData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOrcamento";

export default {
  name: "app-orcamento-form-clienteTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {
        nome: null,
        cnpj_cpf: null,
      },
      clientes: [],
      cliente: null,
      keyToReRender: 0,
      store,
      clienteError: false,
      enderecoError: false,
      clienteFilled: false,
      nomeCliente: null,
      cpfCnpjCliente: null,
      vendedores: [],
      vendedor: null,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  validations: {
    form: ClienteDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLoading(value) {
      this.setLoading(value);
    },
    validateClienteState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSearchCliente(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getClientes(search);
      loading(false);
    }, 350),
    setCliente(cliente) {
      if (!cliente) return;
      this.form = cliente;
      this.clienteFilled = true;
      if (Object.keys(this.$route.params).length !== 0) {
        this.form.solicitante = this.$route.params.orcamentoData.solicitante;
      }
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    setVendedor(vendedor) {
      this.store.formCliente.vendedor = vendedor.id_vendedor;
    },
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 350),
    async saveClient() {
      this.addLoadingToButton("saveClient");

      if (!this.nomeCliente) {
        this.$toast.warning("Informe o nome do cliente");
        this.removeLoadingToButton("saveClient");
        return;
      }
      try {
        const newObjetoData = {
          nome: this.nomeCliente,
          cnpj_cpf: this.cpfCnpjCliente ?? null,
        };
        const response = await this.$http.post("/pessoa/create", newObjetoData);
        if (response.data) {
          this.setCliente(response.data);
        }

        this.$bvModal.hide("newClient");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("saveClient");
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      this.handleLoading(true);
      const id_pessoa = this.$route.params.orcamentoData.cliente.id_pessoa;
      this.vendedor = this.$route.params.orcamentoData?.vendedor;
      this.$http
        .post("/pessoa/find", {
          id_pessoa,
        })
        .then((response) => {
          this.setCliente(response.data);
        })
        .catch((error) =>
          this.$toast.error(`Erro ao buscar cliente:\n${error}`)
        );
      this.handleLoading(false);
    } else {
       this.vendedor = null;
      // this.form = new ClienteData({});
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
  },
  watch: {
    "store.formCliente"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.store.formCliente = newValue;
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          if (this.$v.form.$anyError) {
            this.store.currentTab = oldValue;
            if (this.$v.form.nome.$anyError) {
              this.$toast.error("Informe o cliente!");
              this.clienteError = true;
            }
          }
          return;
        }
      }
    },
  },
};
</script>
